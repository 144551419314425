<template>
  <div class="home">
    <no-cookies />
    <info-card />
  </div>
</template>

<script>
import NoCookies from "@/components/general/noCookies.vue";
import InfoCard from "@/components/general/infoCard.vue";

export default {
  components: { NoCookies, InfoCard },
  setup() {},
};
</script>
<style scoped></style>
